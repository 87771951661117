import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const About = props => (
  <Layout {...props}>
    <Seo title='À propos 👋' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <PageTitle
            header='À propos 👋'
            subheader='TheCode.fr est un blog constructif et inclusif pour les développeurs, devops et les automaticiens 👨‍💻.'
          />
        </Section>
        <Section>
          <p>
            👋l'objectif principal de ce site est de partager les pratiques les
            plus populaires dans l’industrie du logiciel. Plusieurs points forts
            à noter :
          </p>
          <ul>
            <li>Un contenu simple à apprendre et surtout à appliquer 📙</li>
            <li>Tous les artciles sont rédigés en Markdown 😉</li>
            <li>
              Trés fluide (Single Page : développé à l'aide de react js )🚀
            </li>
            <li>Dark mode 💻</li>
            <li>100% réactif 📱</li>
          </ul>
          <p>
            Si vous êtes passionnés comme moi, et que vous souhaitez partager
            vos meilleures pratiques quelque soit votre domaine d'expertise
            (Dev, DevOps, automatisation), n'hésitez pas à me contacter 📧, je
            serai heureux de vous y donner accès 😊.
          </p>
        </Section>

        <Section>
          <Button variant='primary' as={Link} to='/'>
            Page d'accueil
          </Button>
          <Button variant='primary' as={Link} to='/contact'>
            Me contacter
          </Button>
        </Section>
      </Main>
    </Stack>
  </Layout>
)

export default About
